import React, { useEffect, useState } from "react";
import { FaCirclePlay } from "react-icons/fa6";
import { db } from "../firebase.js";
import { doc, getDoc } from "firebase/firestore";

const Types = () => {
  const getData = async () => {
    const docRef = doc(db, "website-db", "types-of-coaching");
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    setInfo({
      oneOnOne: data["one-on-one"],
      group: data["group"],
      corporate: data["corporate"],
      workshop: data["workshop"],
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const [info, setInfo] = useState({
    oneOnOne: { description: null, "video-link": null },
    group: { description: null, "video-link": null },
    corporate: { description: null, "video-link": null },
    workshop: { description: null, "video-link": null },
  });

  function createMarkup(htmlString) {
    return { __html: htmlString };
  }

  return (
    <section
      className="relative py-20 2xl:py-40 overflow-hidden"
      style={{ backgroundColor: "#fbf1db" }}
    >
      <div class="types"></div>
      <div className="mb-20 text-center">
        <span className="text-lg font-bold text-blue-500">
          What kind is right for me?
        </span>
        <h2 className="mt-8 text-7xl font-bold font-heading text-black">
          Types
        </h2>
      </div>
      <img
        className="hidden lg:block absolute top-0 left-0 -ml-96 -mt-40"
        src="zospace-assets/lines/circle.svg"
        alt=""
      />
      <img
        className="hidden lg:block absolute bottom-0 right-0 -mr-32 -mb-72"
        src="zospace-assets/lines/circle.svg"
        alt=""
      />
      <div className="relative container px-4 mx-auto">
        <div>
          <div className="flex flex-wrap -mx-10">
            <div className="relative w-full lg:w-1/2 px-10 mb-10 lg:mb-20">
              <div
                className="px-16 pt-16 pb-24 rounded-lg text-white"
                style={{
                  padding: "30px",
                  height: "fit-content",
                  // overflow: "scroll",
                  backgroundColor: "#253658",
                }}
              >
                <span
                  className="flex mb-10 justify-center items-center w-20 h-20 bg-blue-500 rounded-lg"
                  // style={{ height: "fit-content" }}
                >
                  <svg
                    className="w-8 h-8"
                    width={35}
                    height={35}
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M22.6042 34.9999H2.1875C0.981458 34.9999 0 34.0185 0 32.8124V12.3958C0 11.1897 0.981458 10.2083 2.1875 10.2083H22.6042C23.8102 10.2083 24.7917 11.1897 24.7917 12.3958V32.8124C24.7917 34.0185 23.8102 34.9999 22.6042 34.9999ZM2.1875 11.6666C1.78646 11.6666 1.45833 11.9933 1.45833 12.3958V32.8124C1.45833 33.2149 1.78646 33.5416 2.1875 33.5416H22.6042C23.0052 33.5416 23.3333 33.2149 23.3333 32.8124V12.3958C23.3333 11.9933 23.0052 11.6666 22.6042 11.6666H2.1875Z"
                        fill="white"
                      />
                      <path
                        d="M34.2702 5.83333C33.8677 5.83333 33.541 5.50667 33.541 5.10417V3.64583C33.541 2.43979 32.5596 1.45833 31.3535 1.45833H29.8952C29.4927 1.45833 29.166 1.13167 29.166 0.729167C29.166 0.326667 29.4927 0 29.8952 0H31.3535C33.3646 0 34.9993 1.63625 34.9993 3.64583V5.10417C34.9993 5.50667 34.6727 5.83333 34.2702 5.83333Z"
                        fill="white"
                      />
                      <path
                        d="M6.56217 5.83333C6.15967 5.83333 5.83301 5.50667 5.83301 5.10417V3.64583C5.83301 1.63625 7.4678 0 9.47884 0H10.9372C11.3397 0 11.6663 0.326667 11.6663 0.729167C11.6663 1.13167 11.3397 1.45833 10.9372 1.45833H9.47884C8.2728 1.45833 7.29134 2.43979 7.29134 3.64583V5.10417C7.29134 5.50667 6.96467 5.83333 6.56217 5.83333Z"
                        fill="white"
                      />
                      <path
                        d="M24.0618 1.45833H16.7702C16.3677 1.45833 16.041 1.13167 16.041 0.729167C16.041 0.326667 16.3677 0 16.7702 0H24.0618C24.4643 0 24.791 0.326667 24.791 0.729167C24.791 1.13167 24.4643 1.45833 24.0618 1.45833Z"
                        fill="white"
                      />
                      <path
                        d="M31.3535 29.1666H29.8952C29.4927 29.1666 29.166 28.8399 29.166 28.4374C29.166 28.0349 29.4927 27.7083 29.8952 27.7083H31.3535C32.5596 27.7083 33.541 26.7268 33.541 25.5208V24.0624C33.541 23.6599 33.8677 23.3333 34.2702 23.3333C34.6727 23.3333 34.9993 23.6599 34.9993 24.0624V25.5208C34.9993 27.5303 33.3646 29.1666 31.3535 29.1666Z"
                        fill="white"
                      />
                      <path
                        d="M34.2702 18.9583C33.8677 18.9583 33.541 18.6316 33.541 18.2291V10.9374C33.541 10.5349 33.8677 10.2083 34.2702 10.2083C34.6727 10.2083 34.9994 10.5349 34.9994 10.9374V18.2291C34.9994 18.6316 34.6727 18.9583 34.2702 18.9583Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </span>
                <h3
                  className="mt-12 mb-8 text-4xl font-bold text-white"
                  style={{ fontSize: "2rem" }}
                >
                  1 on 1
                </h3>
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    info.oneOnOne.description
                  )}
                />
                <br />
                <p
                  style={{
                    display: " flex",
                    justifyItems: "center",
                    alignItems: "center",
                    gap: "10px",
                    color: "#f27457",
                    cursor: "pointer",
                  }}
                >
                  <FaCirclePlay />{" "}
                  <div
                    style={{ borderBottom: "solid" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      window.open(info.oneOnOne["video-link"], "_blank");
                    }}
                  >
                    watch to learn more
                  </div>
                </p>
              </div>
            </div>
            <div className="relative w-full lg:w-1/2 px-10 mb-10 lg:mb-20">
              <div
                className="px-16 pt-16 pb-24 bg-gray-600 rounded-lg text-white"
                style={{
                  padding: "30px",
                  height: "fit-content",
                  // overflow: "scroll",
                  backgroundColor: "#253658",
                }}
              >
                <span className="flex mb-10 justify-center items-center w-20 h-20 bg-green-500 rounded-lg">
                  <svg
                    className="w-8 h-8"
                    width={36}
                    height={35}
                    viewBox="0 0 36 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M32.25 35H5.25C3.183 35 1.5 33.3638 1.5 31.3542V3.64588C1.5 3.24338 1.836 2.91672 2.25 2.91672C2.664 2.91672 3 3.24338 3 3.64588V31.3542C3 32.5603 4.0095 33.5417 5.25 33.5417H31.5V6.56255C31.5 6.16005 31.836 5.83338 32.25 5.83338C32.664 5.83338 33 6.16005 33 6.56255V34.2709C33 34.6734 32.664 35 32.25 35Z"
                        fill="white"
                      />
                      <path
                        d="M32.25 7.29167H5.25C3.183 7.29167 1.5 5.65542 1.5 3.64583C1.5 1.63625 3.183 0 5.25 0H29.25C29.664 0 30 0.326667 30 0.729167V5.83333H32.25C32.664 5.83333 33 6.16 33 6.5625C33 6.965 32.664 7.29167 32.25 7.29167ZM5.25 1.45833C4.0095 1.45833 3 2.43979 3 3.64583C3 4.85187 4.0095 5.83333 5.25 5.83333H28.5V1.45833H5.25Z"
                        fill="white"
                      />
                      <path
                        d="M12.9868 27.7055C12.2338 27.7055 11.6158 27.4897 11.1718 27.0566C10.2898 26.1991 10.2808 24.6838 11.1478 22.7866C11.9158 21.1051 13.3018 19.2443 15.0478 17.5482C16.7938 15.8507 18.7063 14.5047 20.4358 13.7565C22.3858 12.9122 23.9443 12.9209 24.8278 13.7799C25.7098 14.6374 25.7188 16.154 24.8518 18.0499C24.0838 19.7313 22.6978 21.5922 20.9518 23.2882C19.2058 24.9857 17.2933 26.3318 15.5638 27.0799C14.5963 27.4955 13.7263 27.7055 12.9868 27.7055ZM23.0143 14.582C22.5043 14.582 21.8188 14.754 21.0448 15.088C19.4803 15.7647 17.7268 17.0043 16.1068 18.5778C14.4868 20.1513 13.2148 21.8561 12.5188 23.3772C11.9488 24.627 11.8378 25.6405 12.2323 26.024C12.6268 26.4076 13.6693 26.2997 14.9548 25.7455C16.5193 25.0688 18.2728 23.8293 19.8928 22.2557C21.5128 20.6822 22.7863 18.9774 23.4823 17.4549C24.0523 16.2051 24.1633 15.1916 23.7688 14.808C23.6113 14.6563 23.3503 14.582 23.0143 14.582Z"
                        fill="white"
                      />
                      <path
                        d="M23.0128 27.7054C22.2733 27.7054 21.4033 27.4969 20.4358 27.0784C18.7063 26.3317 16.7923 24.9842 15.0478 23.2867C13.3018 21.5892 11.9173 19.7298 11.1478 18.0484C10.2808 16.1525 10.2898 14.6359 11.1718 13.7784C12.0523 12.9209 13.6138 12.9121 15.5638 13.755C17.2933 14.5017 19.2073 15.8492 20.9518 17.5467C22.6978 19.2442 24.0823 21.1036 24.8518 22.785C25.7188 24.6823 25.7098 26.1975 24.8278 27.055C24.3838 27.4882 23.7658 27.7054 23.0128 27.7054ZM12.9853 14.5819C12.6478 14.5819 12.3898 14.6563 12.2323 14.8094C11.8378 15.1929 11.9488 16.2065 12.5188 17.4563C13.2148 18.9773 14.4898 20.6821 16.1083 22.2571C17.7268 23.8321 19.4803 25.0702 21.0463 25.7469C22.3318 26.3025 23.3743 26.4075 23.7688 26.0254C24.1633 25.6419 24.0523 24.6284 23.4823 23.3786C22.7863 21.8575 21.5113 20.1527 19.8928 18.5777C18.2743 17.0027 16.5208 15.7646 14.9548 15.0879C14.1808 14.754 13.4953 14.5819 12.9853 14.5819ZM11.7013 14.2932H11.7163H11.7013Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </span>
                <h3
                  className="mt-12 mb-8 text-4xl font-bold text-white"
                  style={{ fontSize: "2rem" }}
                >
                  Group
                </h3>
                <div
                  dangerouslySetInnerHTML={createMarkup(info.group.description)}
                />
                <br />
                <p
                  style={{
                    display: " flex",
                    justifyItems: "center",
                    alignItems: "center",
                    gap: "10px",
                    color: "#f27457",
                    cursor: "pointer",
                  }}
                >
                  <FaCirclePlay />{" "}
                  <div
                    style={{ borderBottom: "solid" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      window.open(info.group["video-link"], "_blank");
                    }}
                  >
                    watch to learn more
                  </div>
                </p>
              </div>
            </div>
            <div className="w-full lg:w-1/2 px-10 mb-10 lg:mb-20">
              <div
                className="px-16 pt-16 pb-24 bg-gray-600 rounded-lg text-white"
                style={{
                  padding: "30px",
                  height: "fit-content",
                  // overflow: "scroll",
                  backgroundColor: "#253658",
                }}
              >
                <span className="flex mb-10 justify-center items-center w-20 h-20 bg-violet-500 rounded-lg">
                  <svg
                    className="w-8 h-8"
                    width={35}
                    height={35}
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M24.0625 18.9583C23.7402 18.9583 23.431 18.8927 23.1467 18.7615L17.5 16.1175L11.8592 18.7585C11.569 18.8927 11.2598 18.9583 10.9375 18.9583C9.73146 18.9583 8.75 17.9769 8.75 16.7708C8.75 16.4529 8.81417 16.1583 8.94833 15.8681L15.5079 1.28917C15.8419 0.510417 16.6265 0 17.5 0C18.3735 0 19.1581 0.510417 19.4965 1.30083L26.0546 15.874C26.1858 16.1583 26.25 16.4529 26.25 16.7708C26.25 17.9769 25.2685 18.9583 24.0625 18.9583ZM17.5 14.5833C17.605 14.5833 17.7115 14.6067 17.8092 14.6519L23.7592 17.4373C24.166 17.6225 24.7917 17.2856 24.7917 16.7708C24.7917 16.6629 24.7712 16.5754 24.7275 16.4763L18.1621 1.88854C17.9346 1.35771 17.061 1.36792 16.8423 1.87833L10.2754 16.4719C10.2287 16.5754 10.2083 16.6629 10.2083 16.7708C10.2083 17.2856 10.8092 17.6327 11.2467 17.4358L17.1908 14.6533C17.2885 14.6067 17.395 14.5833 17.5 14.5833Z"
                        fill="white"
                      />
                      <path
                        d="M17.4997 35C8.65488 35 1.45801 27.8031 1.45801 18.9583C1.45801 12.9194 4.7903 7.46227 10.154 4.71769C10.507 4.53685 10.9518 4.6754 11.1355 5.0356C11.3193 5.3929 11.1763 5.83331 10.8176 6.0156C5.94384 8.50936 2.91634 13.4691 2.91634 18.9583C2.91634 26.9996 9.45842 33.5417 17.4997 33.5417C25.5409 33.5417 32.083 26.9996 32.083 18.9583C32.083 13.4794 29.0613 8.51956 24.1963 6.0156C23.8376 5.83185 23.6961 5.39144 23.8813 5.03414C24.0665 4.67539 24.507 4.53539 24.8628 4.7206C30.2163 7.47394 33.5413 12.931 33.5413 18.9583C33.5413 27.8031 26.3445 35 17.4997 35Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </span>
                <h3
                  className="mt-12 mb-8 text-4xl font-bold text-white"
                  style={{ fontSize: "2rem" }}
                >
                  Corporate
                </h3>
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    info.corporate.description
                  )}
                />
                <br />
                <p
                  style={{
                    display: " flex",
                    justifyItems: "center",
                    alignItems: "center",
                    gap: "10px",
                    color: "#f27457",
                    cursor: "pointer",
                  }}
                >
                  <FaCirclePlay />{" "}
                  <div
                    style={{ borderBottom: "solid" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      window.open(info.corporate["video-link"], "_blank");
                    }}
                  >
                    watch to learn more
                  </div>
                </p>
              </div>
            </div>
            <div className="w-full lg:w-1/2 px-10">
              <div
                className="px-16 pt-16 pb-24 bg-gray-600 rounded-lg text-white"
                style={{
                  padding: "30px",
                  height: "fit-content",
                  // overflow: "scroll",
                  backgroundColor: "#253658",
                }}
              >
                <span className="flex mb-10 justify-center items-center w-20 h-20 bg-pink-500 rounded-lg">
                  <svg
                    className="w-8 h-8"
                    width={35}
                    height={35}
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M24.0618 13.1249C24.0618 12.8935 23.952 12.6758 23.766 12.5384C23.5797 12.4009 23.3394 12.3599 23.1183 12.428L14.3682 15.1203C14.0623 15.2144 13.8535 15.4972 13.8535 15.8173V21.536C13.4245 21.2878 12.9264 21.1457 12.3952 21.1457C10.7844 21.1457 9.47852 22.4515 9.47852 24.0624C9.47852 25.6733 10.7844 26.9791 12.3952 26.9791C14.0044 26.9791 15.3092 25.6758 15.3118 24.0671V24.0624V16.3557L22.6035 14.1122V20.0777C22.1745 19.8294 21.6765 19.6874 21.1452 19.6874C19.5343 19.6874 18.2285 20.9932 18.2285 22.6041C18.2285 24.2149 19.5343 25.5207 21.1452 25.5207C22.7545 25.5207 24.0592 24.2174 24.0618 22.6087V22.6041V13.1249ZM22.6035 22.6041C22.6035 21.7986 21.9506 21.1457 21.1452 21.1457C20.3397 21.1457 19.6868 21.7986 19.6868 22.6041C19.6868 23.4095 20.3397 24.0624 21.1452 24.0624C21.9506 24.0624 22.6035 23.4095 22.6035 22.6041ZM12.3952 22.6041C11.5898 22.6041 10.9368 23.257 10.9368 24.0624C10.9368 24.8678 11.5898 25.5207 12.3952 25.5207C13.2006 25.5207 13.8535 24.8678 13.8535 24.0624C13.8535 23.257 13.2006 22.6041 12.3952 22.6041Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.208 3.64577C8.19447 3.64577 6.56217 5.27807 6.56217 7.2916V8.0937C4.89804 8.43149 3.64551 9.90278 3.64551 11.6666V27.7083C3.64551 29.7218 5.27781 31.3541 7.29134 31.3541H27.708C29.7215 31.3541 31.3538 29.7218 31.3538 27.7083V11.6666C31.3538 9.90278 30.1013 8.43149 28.4372 8.0937V7.2916C28.4372 5.27807 26.8049 3.64577 24.7913 3.64577H10.208ZM26.9788 8.02077V7.2916C26.9788 6.08347 25.9994 5.1041 24.7913 5.1041H10.208C8.99988 5.1041 8.02051 6.08347 8.02051 7.2916V8.02077H26.9788ZM7.29134 9.4791C6.08321 9.4791 5.10384 10.4585 5.10384 11.6666V27.7083C5.10384 28.9163 6.08321 29.8958 7.29134 29.8958H27.708C28.9161 29.8958 29.8955 28.9163 29.8955 27.7083V11.6666C29.8955 10.4585 28.9161 9.4791 27.708 9.4791H7.29134Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <h3
                  className="mt-12 mb-8 text-4xl font-bold text-white"
                  style={{ fontSize: "2rem" }}
                >
                  Workshop
                </h3>
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    info.workshop.description
                  )}
                />
                <br />
                <p
                  style={{
                    display: " flex",
                    justifyItems: "center",
                    alignItems: "center",
                    gap: "10px",
                    color: "#f27457",
                    cursor: "pointer",
                  }}
                >
                  <FaCirclePlay />{" "}
                  <div
                    style={{ borderBottom: "solid" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      window.open(
                        "https://www.youtube.com/embed/nFx6yKZrzco",
                        "_blank"
                      );
                    }}
                  >
                    watch to learn more
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Types;
